import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  Flex,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

interface PoseLandmarkerErrorAlertDialogProps {
  isLoaded: boolean;
  timedOut?: boolean;
  error?: Error;
  onRetry: () => void;
}

export const PoseLandmarkerErrorAlertDialog = ({
  isLoaded,
  error,
  timedOut,
  onRetry,
}: PoseLandmarkerErrorAlertDialogProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [secondsRemaining, setSecondsRemaining] = useState(10);
  const retryButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const open = !!(error || timedOut) && !isLoaded;
    if (open) {
      onOpen();
    } else {
      onClose();
    }
  }, [error, timedOut, isLoaded]);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setSecondsRemaining((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isOpen]);

  useEffect(() => {
    if (secondsRemaining === 0) {
      onRetry();
    }
  }, [secondsRemaining, onRetry]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={() => {}}
      leastDestructiveRef={retryButtonRef}
      closeOnEsc
    >
      <AlertDialogOverlay>
        <AlertDialogContent color="var(--chakra-colors-chakra-body-text)">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Loading the Pose Model Failed
          </AlertDialogHeader>
          <AlertDialogCloseButton onClick={onClose} />
          <AlertDialogBody>
            <Flex direction="column" gap={3}>
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  {error
                    ? "An unexected error occurred while loading."
                    : "Loading did not finish in the expected amount of time."}
                </AlertDescription>
              </Alert>
              <Text>Please reload the app to try again.</Text>
              <Text>
                If this happens repeatedly, please contact us at{" "}
                <Link href="mailto:info@handstandtimer.com">
                  info@handstandtimer.com
                </Link>{" "}
                for support.
              </Text>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter gap={3}>
            <Button onClick={onClose}>Cancel</Button>{" "}
            <Button ref={retryButtonRef} onClick={onRetry} colorScheme="blue">
              Retry ({secondsRemaining}s)
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
